import React, { useState, useEffect, useRef } from "react";
import "./VocabularyBuilder.css";
import { db, auth } from "./firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { ArrowLeftCircle, ArrowRightCircle, Book, Star } from "lucide-react";

const shuffleArray = (array) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const randomizeOptions = (word) => {
  const shuffledOptions = shuffleArray([...word.options]);
  return { ...word, options: shuffledOptions };
};

const VocabularyBuilder = () => {
  const [words, setWords] = useState([]);
  const [currentWord, setCurrentWord] = useState(null);
  const [showQuiz, setShowQuiz] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);
  const [favorites, setFavorites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState("words");
  const audioRef = useRef(null);

  useEffect(() => {
    document.title = "Vocabulary Builder";
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        fetchQuizData();
        retrieveUserState(user.uid);
      } else {
        setUser(null);
        setWords([]);
        setFavorites([]);
      }
    });
    return () => {
      unsubscribe();
      document.title = "Campus Goals";
    };
  }, []);

  const fetchQuizData = async () => {
    try {
      setIsLoading(true);
      const quizType = "vocabulary";
      const quizQuery = query(
        collection(db, "users"),
        where("quiztype", "==", quizType),
      );
      const querySnapshot = await getDocs(quizQuery);
      const data = querySnapshot.docs.map((doc) => doc.data());
      const shuffledWords = shuffleArray(data).map(randomizeOptions);
      const sortedWords = shuffledWords.sort((a, b) =>
        a.word.localeCompare(b.word),
      );
      setWords(sortedWords);
      setIsLoading(false);
    } catch (e) {
      console.error("Error fetching quiz data:", e);
      setError("Failed to load quiz data. Please try again later.");
      setIsLoading(false);
    }
  };

  const retrieveUserState = async (userId) => {
    try {
      const builderDocRef = doc(db, "builders", userId);
      const builderDocSnap = await getDoc(builderDocRef);

      if (builderDocSnap.exists()) {
        const userData = builderDocSnap.data();
        setFavorites(userData.favorites || []);
      } else {
        await setDoc(builderDocRef, { favorites: [] });
        setFavorites([]);
      }
    } catch (e) {
      console.error("Error retrieving user state:", e);
    }
  };

  const saveUserState = async (newFavorites) => {
    if (user) {
      try {
        const builderDocRef = doc(db, "builders", user.uid);
        await setDoc(builderDocRef, { favorites: newFavorites }, { merge: true });
      } catch (e) {
        console.error("Error saving user state:", e);
      }
    }
  };

  const handleDisplayWordGrid = () => {
    setShowWelcome(false);
  };

  const handleWordClick = (word) => {
    setCurrentWord(randomizeOptions(word));
    setShowQuiz(false);
    setSelectedAnswer(null);
    setShowResult(false);
  };

  const handleQuizStart = () => {
    setCurrentWord(randomizeOptions(currentWord));
    setShowQuiz(true);
    setSelectedAnswer(null);
    setShowResult(false);
  };

  const handleAnswerSelect = (answer) => {
    setSelectedAnswer(answer);
  };

  const handleQuizSubmit = () => {
    setShowResult(true);
  };

  const handleBackToGrid = () => {
    setCurrentWord(null);
    setShowQuiz(false);
    setSelectedAnswer(null);
    setShowResult(false);
  };

  const handlePlayButtonClick = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const toggleFavorite = (word) => {
    setFavorites((prevFavorites) => {
      const newFavorites = prevFavorites.some((fav) => fav.word === word.word)
        ? prevFavorites.filter((fav) => fav.word !== word.word)
        : [...prevFavorites, word];

      saveUserState(newFavorites);

      return newFavorites;
    });
  };

  const handleNextWord = () => {
    const currentIndex = words.findIndex((w) => w.word === currentWord.word);
    const nextIndex = (currentIndex + 1) % words.length;
    setCurrentWord(randomizeOptions(words[nextIndex]));
    setShowQuiz(false);
    setSelectedAnswer(null);
    setShowResult(false);
  };

  const handlePrevWord = () => {
    const currentIndex = words.findIndex((w) => w.word === currentWord.word);
    const prevIndex = (currentIndex - 1 + words.length) % words.length;
    setCurrentWord(randomizeOptions(words[prevIndex]));
    setShowQuiz(false);
    setSelectedAnswer(null);
    setShowResult(false);
  };

  const renderWelcomeSection = () => (
    <div className="vb-welcome-section">
      <h1>Welcome to Vocabulary Builder</h1>
      <p>
        Expand your vocabulary and enhance your language skills with our
        interactive word learning tool. Here's how it works:
      </p>
      <ul>
        <li>Browse through a curated list of words</li>
        <li>Click on a word to view its details and take a quiz</li>
        <li>Test your knowledge with multiple-choice questions</li>
        <li>Track your progress and favorite challenging words</li>
      </ul>
      <p>
        Also, personalize your learning experience by saving words to your favorites list:
      </p>
      <ul>
        <li>Mark words as favorites for quick access</li>
        <li>Review your favorite words anytime</li>
        <li>Focus on challenging vocabulary tailored to your needs</li>
      </ul>
      <p>
        Ready to get started? Click the button below to explore the word grid
        and begin your learning journey!
      </p>
      <button className="vb-display-grid-button" onClick={handleDisplayWordGrid}>
        Display Word Grid
      </button>
    </div>
  );

  const renderWordGrid = () => (
    <div className="vb-word-grid">
      {words.map((word, index) => (
        <div key={index} className="vb-word-item-container">
          <div
            className={`vb-word-item ${word.difficulty}`}
            onClick={() => handleWordClick(word)}
          >
            <div className="vb-word-content">
              <div className="vb-word">{word.word}</div>
              <div className="vb-part-of-speech">{word.part_of_speech}</div>
            </div>
          </div>
          <button
            className={`vb-favorite-button ${
              favorites.some((fav) => fav.word === word.word) ? "favorited" : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              toggleFavorite(word);
            }}
          >
            ★
          </button>
        </div>
      ))}
    </div>
  );

  const renderWordDetail = () => (
    <div className="vb-word-detail">
      <div className="vb-word-header">
        <div className="vb-word-info">
          <span className="vb-word">{currentWord.word}</span>
          <span className="vb-part-of-speech">({currentWord.part_of_speech})</span>
          <button
            onClick={handlePlayButtonClick}
            className="vocab-quiz-play-button"
            aria-label="Play pronunciation"
          >
            ▶️
          </button>
        </div>
      </div>
      <audio
        ref={audioRef}
        src={`/audio/${currentWord.word}.mp3`}
        className="vocab-quiz-audio"
      >
        Your browser does not support the audio element.
      </audio>
      <p>
        <strong>Explanation:</strong> {currentWord.explanation}
      </p>
      <p>
        <strong>Sample Sentence:</strong> {currentWord.sample_sentence}
      </p>
      <p>
        <strong>Tips to Remember:</strong> {currentWord.tips_to_remember}
      </p>
      <p>
        <strong>Additional Info:</strong> {currentWord.additional_info}
      </p>
      <div className="vb-button-container">
        <button className="vb-nav-button vb-prev-button" onClick={handlePrevWord} aria-label="Previous Word">
          <ArrowLeftCircle size={24} />
        </button>
        <button className="vb-quiz-button" onClick={handleQuizStart}>
          Take Quiz
        </button>
        <button className="vb-back-button" onClick={handleBackToGrid}>
          Back to Word Grid
        </button>
        <button className="vb-nav-button vb-next-button" onClick={handleNextWord} aria-label="Next Word">
          <ArrowRightCircle size={24} />
        </button>
      </div>
      <div className="vb-favorite-container">
        <button
          className={`vb-favorite-button ${
            favorites.some((fav) => fav.word === currentWord.word)
              ? "favorited"
              : ""
          }`}
          onClick={() => toggleFavorite(currentWord)}
        >
          ★
        </button>
      </div>
    </div>
  );

  const renderQuiz = () => (
    <div className="vb-quiz-mode">
      <div className="vb-word-header">
        <div className="vb-word-info">
          <span className="vb-word">{currentWord.word}</span>
          <span className="vb-part-of-speech">({currentWord.part_of_speech})</span>
          <button
            onClick={handlePlayButtonClick}
            className="vocab-quiz-play-button"
            aria-label="Play pronunciation"
          >
            ▶️
          </button>
        </div>
      </div>
      <audio
        ref={audioRef}
        src={`/audio/${currentWord.word}.mp3`}
        className="vocab-quiz-audio"
      >
        Your browser does not support the audio element.
      </audio>
      <p>Choose the correct definition:</p>
      <div className="vb-quiz-options">
        {currentWord.options.map((option, index) => (
          <button
            key={index}
            onClick={() => handleAnswerSelect(option)}
            className={`vb-quiz-option ${
              selectedAnswer === option ? "selected" : ""
            } ${
              showResult
                ? option === currentWord.correct_answer
                  ? "correct"
                  : selectedAnswer === option
                  ? "incorrect"
                  : ""
                : ""
            }`}
            disabled={showResult}
          >
            <span className="vb-quiz-option-text">{option}</span>
          </button>
        ))}
      </div>
      {showResult && (
        <div className="vb-quiz-result">
          <p
            className={`answer-status ${
              selectedAnswer === currentWord.correct_answer
                ? "correct"
                : "incorrect"
            }`}
          >
            {selectedAnswer === currentWord.correct_answer ? (
              "Correct!"
            ) : (
              <>
                Incorrect!
                <br />
                <span className="correct-answer">
                  The correct answer is: {currentWord.correct_answer}
                </span>
              </>
            )}
          </p>
        </div>
      )}
      <div className="vb-button-container">
        <button className="vb-nav-button vb-prev-button" onClick={handlePrevWord} aria-label="Previous Word">
          <ArrowLeftCircle size={24} />
        </button>
        <button
          className="vb-submit-button"
          onClick={handleQuizSubmit}
          disabled={!selectedAnswer || showResult}
        >
          Submit
        </button>
        <button className="vb-back-button" onClick={() => setShowQuiz(false)}>
          Back to Word Detail
        </button>
        <button className="vb-back-button" onClick={handleBackToGrid}>
          Back to Word Grid
        </button>
        <button className="vb-nav-button vb-next-button" onClick={handleNextWord} aria-label="Next Word">
          <ArrowRightCircle size={24} />
        </button>
      </div>
      <div className="vb-favorite-container">
        <button
          className={`vb-favorite-button ${
            favorites.some((fav) => fav.word === currentWord.word)
              ? "favorited"
              : ""
          }`}
          onClick={() => toggleFavorite(currentWord)}
        >
          ★
        </button>
      </div>
    </div>
  );

  const renderFavorites = () => (
    <div className="vb-favorites">
      <h2>Favorite Words</h2>
      {favorites.length === 0 ? (
        <p>No favorites yet. Click the star icon to add words to your favorites.</p>
      ) : (
        <div className="vb-favorites-grid">
          {favorites.map((word, index) => (
            <div key={index} className="vb-word-item-container">
              <div
                className={`vb-word-item ${word.difficulty}`}
                onClick={() => handleWordClick(word)}
              >
                <div className="vb-word-content">
                  <div className="vb-word">{word.word}</div>
                  <div className="vb-part-of-speech">{word.part_of_speech}</div>
                </div>
              </div>
              <button
                className="vb-favorite-button favorited"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFavorite(word);
                }}
              >
                ★
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div className="vb-container">
      <div className="vb-main">
        {isLoading && <div className="vb-loading">Loading...</div>}
        {error && <div className="vb-error">{error}</div>}
        {!isLoading && !error && (
          <div className="vb-content-wrapper">
            {showWelcome && renderWelcomeSection()}
            {!showWelcome && (
              <>
                {!currentWord && !showQuiz && (
                <div className="vb-tabs">
                  <button
                    className={`vb-tab ${activeTab === "words" ? "active" : ""}`}
                    onClick={() => setActiveTab("words")}
                  >
                    <Book size={18} />
                    <span>Words</span>
                  </button>
                  <button
                    className={`vb-tab ${activeTab === "favorites" ? "active" : ""}`}
                    onClick={() => setActiveTab("favorites")}
                  >
                    <Star size={18} />
                    <span>Favorites</span>
                  </button>
                </div>
                )}
                {activeTab === "words" && !currentWord && renderWordGrid()}
                {activeTab === "favorites" && !currentWord && renderFavorites()}
                {currentWord && !showQuiz && renderWordDetail()}
                {currentWord && showQuiz && renderQuiz()}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default VocabularyBuilder;