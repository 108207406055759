import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { auth } from './firebase';
import { signOut } from 'firebase/auth';
import { User, LogOut, ChevronUp, ChevronDown } from 'lucide-react';

const Navbar = ({ user }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const hamburgerMenuRef = useRef(null);
  const navbarRef = useRef(null);
  const profileDropdownRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
    setProfileDropdownOpen(false);
    setDropdownOpen(false);
  };

  const handleDropdownClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownOpen((prev) => !prev);
    setProfileDropdownOpen(false);
  };

  const handleLinkClick = () => {
    setMenuOpen(false);
    setDropdownOpen(false);
    setProfileDropdownOpen(false);
  };

  const handleProfileClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setProfileDropdownOpen((prev) => !prev);
    setMenuOpen(false);
    setDropdownOpen(false);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileDropdownRef.current &&
        !profileDropdownRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !hamburgerMenuRef.current.contains(event.target)
      ) {
        setProfileDropdownOpen(false);
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [profileDropdownRef, dropdownRef]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setMenuOpen(false);
        setDropdownOpen(false);
        setProfileDropdownOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className="navbar" ref={navbarRef}>
      <div className="navbar-logo-container">
        <img src="./logo.jpg" alt="Campus Goals Logo" className="navbar-logo" />
      </div>

      <ul className={`navbar-menu ${menuOpen ? 'active' : ''}`}>
        <li><Link to="/AboutUs" onClick={handleLinkClick}>About Us</Link></li>
        <li><Link to="/disclaimer" onClick={handleLinkClick}>Disclaimer</Link></li>
        <li><Link to="/ContactForm" onClick={handleLinkClick}>Contact Us</Link></li>
        <li className={`dropdown ${dropdownOpen ? 'active' : ''}`} ref={dropdownRef}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" onClick={handleDropdownClick}>GRE Prep</a>
          <ul className={`dropdown-menu ${dropdownOpen ? 'active' : ''}`}>
            <li><Link to="/quiz" onClick={handleLinkClick}>Vocabulary Quiz</Link></li>
            <li><Link to="/tcseQuiz" onClick={handleLinkClick}>Text Completion Quiz</Link></li>
            <li><Link to="/rcQuiz" onClick={handleLinkClick}>Reading Comprehension Quiz</Link></li>
            <li><Link to="/synonymsQuiz" onClick={handleLinkClick}>Quick Quiz</Link></li>
            <li><Link to="/vocabularyBuilder" onClick={handleLinkClick}>Vocabulary Builder</Link></li>
            <li><Link to="/mathQuiz" onClick={handleLinkClick}>Math Quiz</Link></li>
          </ul>
        </li>
      </ul>

      {user && (
      <div className="profile-dropdown-container">
        <div
          className={`profile-dropdown ${profileDropdownOpen ? 'active' : ''}`}
          onClick={handleProfileClick}
          ref={profileDropdownRef}
        >
          <img src={user.photoURL} alt="Profile" className="profile-picture" />
          {profileDropdownOpen ? (
            <ChevronUp className="profile-dropdown-arrow" size={18} />
          ) : (
            <ChevronDown className="profile-dropdown-arrow" size={18} />
          )}
          <ul className={`profile-dropdown-menu ${profileDropdownOpen ? 'active' : ''}`}>
            <li>
              <Link to="/ProfilePage" onClick={handleLinkClick}>
                <User size={18} />
                Profile
              </Link>
            </li>
            <li>
              <button onClick={handleSignOut}>
                <LogOut size={18} />
                Sign Out
              </button>
            </li>
          </ul>
        </div>
      </div>
      )}

      <button
        className={`hamburger-menu ${menuOpen ? 'open' : ''}`}
        onClick={handleMenuClick}
        ref={hamburgerMenuRef}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
    </nav>
  );
};

export default Navbar;