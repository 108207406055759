// components/Calculator.js
import React, { useState } from "react";
import "./Calculator.css"; // Ensure you have a CSS file for styling

const Calculator = () => {
  const [display, setDisplay] = useState("0");
  const [memory, setMemory] = useState(0);
  const [operator, setOperator] = useState(null);
  const [waitingForOperand, setWaitingForOperand] = useState(false);

  const inputDigit = (digit) => {
    if (waitingForOperand) {
      setDisplay(String(digit));
      setWaitingForOperand(false);
    } else {
      setDisplay(display === "0" ? String(digit) : display + digit);
    }
  };

  const inputDot = () => {
    if (waitingForOperand) {
      setDisplay("0.");
      setWaitingForOperand(false);
    } else if (display.indexOf(".") === -1) {
      setDisplay(display + ".");
    }
  };

  const clearDisplay = () => {
    setDisplay("0");
  };

  const clearEntry = () => {
    setDisplay("0");
  };

  const toggleSign = () => {
    setDisplay(display.charAt(0) === "-" ? display.substr(1) : "-" + display);
  };

  const inputPercent = () => {
    const value = parseFloat(display);
    setDisplay(String(value / 100));
  };

  const performOperation = (nextOperator) => {
    const operations = {
      "/": (prevValue, nextValue) => prevValue / nextValue,
      "*": (prevValue, nextValue) => prevValue * nextValue,
      "+": (prevValue, nextValue) => prevValue + nextValue,
      "-": (prevValue, nextValue) => prevValue - nextValue,
      "=": (prevValue, nextValue) => nextValue,
    };

    const inputValue = parseFloat(display);

    if (memory === 0) {
      setMemory(inputValue);
    } else {
      const currentValue = memory || 0;
      const computedValue = operations[operator](currentValue, inputValue);
      setMemory(computedValue);
      setDisplay(String(computedValue));
    }

    setWaitingForOperand(true);
    setOperator(nextOperator);
  };

  const handleMemoryClear = () => {
    setMemory(0);
  };

  const handleMemoryRecall = () => {
    setDisplay(String(memory));
  };

  const handleMemoryAdd = () => {
    setMemory(memory + parseFloat(display));
  };

  const handleMemorySubtract = () => {
    setMemory(memory - parseFloat(display));
  };

  const handleSquareRoot = () => {
    setDisplay(String(Math.sqrt(parseFloat(display))));
  };

  const handleSquare = () => {
    setDisplay(String(Math.pow(parseFloat(display), 2)));
  };

  const handleCube = () => {
    setDisplay(String(Math.pow(parseFloat(display), 3)));
  };

  const handleCubeRoot = () => {
    setDisplay(String(Math.cbrt(parseFloat(display))));
  };

  const handlePi = () => {
    setDisplay(String(Math.PI));
  };

  const handleTrigFunction = (func) => {
    const value = parseFloat(display);
    switch (func) {
      case "sin":
        setDisplay(String(Math.sin(value)));
        break;
      case "cos":
        setDisplay(String(Math.cos(value)));
        break;
      case "tan":
        setDisplay(String(Math.tan(value)));
        break;
      default:
        break;
    }
  };

  const handleBackspace = () => {
    setDisplay(display.length === 1 ? "0" : display.slice(0, -1));
  };

  return (
    <div className="calculator">
      <div className="calculator-display">{display}</div>
      <div className="calculator-keypad">
        <div className="function-keys">
          <button onClick={clearDisplay}>AC</button>
          <button onClick={clearEntry}>CE</button>
          <button onClick={toggleSign}>±</button>
          <button onClick={inputPercent}>%</button>
        </div>
        <div className="digit-keys">
          {Array.from({ length: 9 }, (_, i) => i + 1).map((num) => (
            <button key={num} onClick={() => inputDigit(num)}>
              {num}
            </button>
          ))}
          <button onClick={() => inputDigit(0)}>0</button>
          <button onClick={inputDot}>.</button>
          <button onClick={handleBackspace} className="backspace">
            ⌫
          </button>
        </div>
        <div className="operator-keys">
          <button onClick={() => performOperation("/")}>÷</button>
          <button onClick={() => performOperation("*")}>×</button>
          <button onClick={() => performOperation("-")}>−</button>
          <button onClick={() => performOperation("+")}>+</button>
          <button onClick={() => performOperation("=")}>=</button>
        </div>
        <div className="memory-functions">
          <button onClick={handleMemoryClear}>MC</button>
          <button onClick={handleMemoryRecall}>MR</button>
          <button onClick={handleMemoryAdd}>M+</button>
          <button onClick={handleMemorySubtract}>M-</button>
        </div>
        <div className="trig-functions">
          <button onClick={() => handleTrigFunction("sin")}>sin</button>
          <button onClick={() => handleTrigFunction("cos")}>cos</button>
          <button onClick={() => handleTrigFunction("tan")}>tan</button>
          <button onClick={handlePi}>π</button>
        </div>
        <div className="advanced-functions">
          <button onClick={handleSquareRoot}>√</button>
          <button onClick={handleSquare}>x²</button>
          <button onClick={handleCube}>x³</button>
          <button onClick={handleCubeRoot}>∛</button>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
