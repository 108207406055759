import React, { useState, useRef, useEffect, useCallback } from "react";
import "./Quiz.css";
import { shuffleArray, correctFeedback, incorrectFeedback } from "./utils";
import WelcomeScreen from "./WelcomeScreen";
import QuizQuestion from "./QuizQuestion";
import QuizOptions from "./QuizOptions";
import FeedbackSection from "./FeedbackSection";
import ResultScreen from "./ResultScreen";
import { db, auth } from "./firebase"; // Import Firestore and Auth from firebase.js
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";

const Quiz = () => {
  const [quizData, setQuizData] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [score, setScore] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [quizEnded, setQuizEnded] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [isFirstAttempt, setIsFirstAttempt] = useState(true);
  const [numQuestions, setNumQuestions] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [quizSummary, setQuizSummary] = useState([]); // Add this state
  const [hasSavedQuiz, setHasSavedQuiz] = useState(false); // Add this state
  const [savedQuizState, setSavedQuizState] = useState(null); // Add this state
  const audioRef = useRef(null);

  useEffect(() => {
    document.title = "Vocabulary Quiz";
    return () => {
      document.title = "Campus Goals"; // Reset title when component unmounts
    };
  }, []);

  const saveQuizState = useCallback(async (forcedState = null) => {
    const userId = auth.currentUser.uid;
    const quizState = forcedState || {
      currentQuestion,
      selectedOption,
      score,
      showAnswer,
      quizEnded,
      feedback,
      isFirstAttempt,
      numQuestions,
      questions,
      quizSummary,
    };

    // Filter out undefined values from quizSummary
    quizState.quizSummary = quizState.quizSummary.map(item => ({
      question: item.question ?? '',
      userAnswer: item.userAnswer ?? null,
      correctAnswer: item.correctAnswer ?? '',
      isCorrect: item.isCorrect ?? false
    }));

    await setDoc(doc(db, "vocabquizStates", userId), quizState);
  }, [currentQuestion, selectedOption, score, showAnswer, quizEnded, feedback, isFirstAttempt, numQuestions, questions, quizSummary]);

  const retrieveQuizState = async () => {
    const userId = auth.currentUser.uid;
    const quizStateDoc = await getDoc(doc(db, "vocabquizStates", userId));
    if (quizStateDoc.exists()) {
      const quizState = quizStateDoc.data();
      if (quizState.quizEnded) {
        // If the quiz had ended, reset to initial state
        setIsFirstAttempt(true);
        setQuizEnded(false);
      } else {
        // Otherwise, restore the saved state
        setCurrentQuestion(quizState.currentQuestion ?? 0);
        setSelectedOption(quizState.selectedOption ?? null);
        setScore(quizState.score ?? 0);
        setShowAnswer(quizState.showAnswer ?? false);
        setQuizEnded(quizState.quizEnded ?? false);
        setFeedback(quizState.feedback ?? "");
        setIsFirstAttempt(quizState.isFirstAttempt ?? true);
        setNumQuestions(quizState.numQuestions ?? 10);
        setQuestions(quizState.questions ?? []);
        setQuizSummary(quizState.quizSummary ?? []);
        setHasSavedQuiz(true); // Set hasSavedQuiz to true if there is a saved state
        setSavedQuizState(quizState); // Set savedQuizState
      }
    } else {
      setHasSavedQuiz(false); // Set hasSavedQuiz to false if there is no saved state
    }
  };

  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        const quizType = "vocabulary";
        const quizQuery = query(
          collection(db, "users"),
          where("quiztype", "==", quizType),
        );
        const querySnapshot = await getDocs(quizQuery);
        const data = querySnapshot.docs.map((doc) => doc.data());
        setQuizData(data);
        setIsLoading(false);
      } catch (e) {
        console.error("Error fetching quiz data:", e);
        setError("Failed to load quiz data. Please try again later.");
        setIsLoading(false);
      }
    };

    fetchQuizData();
    retrieveQuizState();
  }, []);

  const getShuffledQuestions = useCallback(() => {
    const randomizeOptions = (question) => {
      const shuffledOptions = shuffleArray([...question.options]);
      const newOptions = Object.fromEntries(
        shuffledOptions.map((value, index) => [
          String.fromCharCode(65 + index),
          value,
        ]),
      );
      return { ...question, options: newOptions };
    };

    const shuffledQuestions =
      quizData.length > 0 ? shuffleArray([...quizData]) : [];
    const selectedQuestions = shuffledQuestions
      .slice(0, numQuestions)
      .map(randomizeOptions);
    return selectedQuestions;
  }, [numQuestions, quizData]);

  useEffect(() => {
    if (quizData.length > 0 && questions.length === 0) {
      setQuestions(getShuffledQuestions());
    }
  }, [getShuffledQuestions, quizData, questions.length]);

  const currentQuiz = questions[currentQuestion];

  const handleOptionClick = (optionValue) => {
    setSelectedOption(optionValue);
  };

  const handleCheckAnswer = () => {
    console.log("Current Quiz:", currentQuiz); // Add this line to debug
    const isCorrect = selectedOption === currentQuiz.correct_answer;
    if (isCorrect) {
      setScore(score + 1);
      setFeedback(
        correctFeedback[Math.floor(Math.random() * correctFeedback.length)],
      );
    } else {
      setFeedback(
        incorrectFeedback[Math.floor(Math.random() * incorrectFeedback.length)],
      );
    }
    setShowAnswer(true);

    // Update quiz summary with actual text of the user's answer
    setQuizSummary([
      ...quizSummary,
      {
        question: currentQuiz.word, // Use currentQuiz.word instead of currentQuiz.question
        userAnswer: selectedOption,
        correctAnswer: currentQuiz.correct_answer,
        isCorrect: isCorrect,
      },
    ]);
  };

  const handleNextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
      setSelectedOption(null);
      setShowAnswer(false);
      setFeedback("");
    } else {
      setQuizEnded(true);
    }
  };

  const handleRestartQuiz = async () => {
    setCurrentQuestion(0);
    setSelectedOption(null);
    setScore(0);
    setShowAnswer(false);
    setQuizEnded(false);
    setFeedback("");
    setIsFirstAttempt(true);
    setQuizSummary([]);

    const newQuestions = getShuffledQuestions();
    setQuestions(newQuestions);

    const resetState = {
      currentQuestion: 0,
      selectedOption: null,
      score: 0,
      showAnswer: false,
      quizEnded: false,
      feedback: "",
      isFirstAttempt: true,
      quizSummary: [],
      questions: newQuestions,
      numQuestions,
    };

    await saveQuizState(resetState);
    await deleteDoc(doc(db, "vocabquizStates", auth.currentUser.uid));
    setHasSavedQuiz(false); // Reset hasSavedQuiz state
  };

  const handlePlayButtonClick = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const startQuiz = async () => {
    const newQuestions = getShuffledQuestions();
    setQuestions(newQuestions);
    setCurrentQuestion(0);
    setSelectedOption(null);
    setScore(0);
    setShowAnswer(false);
    setQuizEnded(false);
    setFeedback("");
    setIsFirstAttempt(false);
    setQuizSummary([]);

    const resetState = {
      currentQuestion: 0,
      selectedOption: null,
      score: 0,
      showAnswer: false,
      quizEnded: false,
      feedback: "",
      isFirstAttempt: false,
      quizSummary: [],
      questions: newQuestions,
      numQuestions,
    };

    await saveQuizState(resetState);
    await deleteDoc(doc(db, "vocabquizStates", auth.currentUser.uid));
    setHasSavedQuiz(false); // Reset hasSavedQuiz state
  };

  const resumeQuiz = () => {
    setIsFirstAttempt(false);
    setHasSavedQuiz(false); // Reset hasSavedQuiz state
  };

  useEffect(() => {
    if (!isFirstAttempt) {
      saveQuizState();
    }
  }, [
    currentQuestion,
    selectedOption,
    score,
    showAnswer,
    quizEnded,
    feedback,
    isFirstAttempt,
    numQuestions,
    questions,
    saveQuizState,
  ]);

  useEffect(() => {
    console.log("Quiz Summary:", quizSummary); // Add this line to debug
  }, [quizSummary]);

  if (isLoading) {
    return (
      <div className="vocab-quiz-wrapper">
        <div className="vocab-quiz-main">
          <div>Loading...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="vocab-quiz-wrapper">
        <div className="vocab-quiz-main">
          <div>{error}</div>
        </div>
      </div>
    );
  }

  if (quizEnded) {
    return (
      <ResultScreen
        score={score}
        questions={questions}
        handleRestartQuiz={handleRestartQuiz}
        quizSummary={quizSummary}
      />
    );
  }

  if (questions.length === 0) {
    return (
      <div className="vocab-quiz-wrapper">
        <div className="vocab-quiz-main">
          <div>No questions available. Please try again later.</div>
        </div>
      </div>
    );
  }

  return (
    <div className="vocab-quiz-wrapper">
      <div className="vocab-quiz-main">
        <div className="vocab-quiz-content">
          {isFirstAttempt || hasSavedQuiz ? (
            <WelcomeScreen
              numQuestions={numQuestions}
              setNumQuestions={setNumQuestions}
              startQuiz={startQuiz}
              hasSavedQuiz={hasSavedQuiz}
              resumeQuiz={resumeQuiz}
              savedQuizState={savedQuizState}
            />
          ) : (
            <>
              <QuizQuestion
                currentQuiz={currentQuiz}
                currentQuestion={currentQuestion}
                questions={questions}
                score={score}
                handlePlayButtonClick={handlePlayButtonClick}
                audioRef={audioRef}
              />
              <QuizOptions
                currentQuiz={currentQuiz}
                selectedOption={selectedOption}
                handleOptionClick={handleOptionClick}
                showAnswer={showAnswer}
              />
              {!showAnswer ? (
                <button
                  className="quiz-button"
                  onClick={handleCheckAnswer}
                  disabled={selectedOption === null}
                >
                  Check Answer
                </button>
              ) : (
                <FeedbackSection
                  feedback={feedback}
                  currentQuiz={currentQuiz}
                  selectedOption={selectedOption}
                  handleNextQuestion={handleNextQuestion}
                  isLastQuestion={currentQuestion === questions.length - 1}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Quiz;