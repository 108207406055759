// components/SynProgressBar.js
import React from 'react';

const SynProgressBar = ({ score, total }) => {
  const percentage = ((score + 1) / total) * 100;

  return (
    <div className="syn-progress-bar">
      <div 
        className="syn-progress" 
        style={{ width: `${percentage}%` }}
        aria-valuenow={score + 1}
        aria-valuemin="1"
        aria-valuemax={total}
      />
    </div>
  );
};

export default SynProgressBar;