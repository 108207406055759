import React, { useEffect } from "react";
import "./AboutUs.css";

const Disclaimer = () => {

  useEffect(() => {
    document.title = "Disclaimer";
    return () => {
      document.title = "Campus Goals"; // Reset title when component unmounts
    };
  }, []);
  
  return (
    <div className="about-us-wrapper">
      <div className="about-us-main">
        <h2 className="about-us-header">Disclaimer</h2>
        <p className="about-us-description">
          The materials provided on this website, including but not limited to quizzes, study guides, and educational content, are intended for informational and educational purposes only.
        </p>
        <p className="about-us-description">
          All content is provided "as is" without any guarantees or warranties of any kind, either express or implied. Users are encouraged to use the materials at their own discretion and to verify the information independently.
        </p>
        <p className="about-us-description">
          There is no guarantee that the materials provided will appear in actual tests or assessments. The content may not reflect the most current information or developments in the relevant fields.
        </p>
        <p className="about-us-description">
          While we strive to provide accurate and up-to-date information, errors or inaccuracies may occur in the materials. Users should be aware that mistakes may appear in the tests or quizzes, and we recommend double-checking answers and consulting additional resources when preparing for exams.
        </p>
        <p className="about-us-description">
          The creators of this website shall not be held liable for any direct, indirect, incidental, or consequential damages arising from the use or inability to use the materials provided. By using this website, you acknowledge that you have read and understood this disclaimer and agree to its terms.
        </p>
        <p className="about-us-description">
          If you have any questions or concerns regarding this disclaimer, please feel free to contact us.
        </p>
      </div>
    </div>
  );
};

export default Disclaimer;