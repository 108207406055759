import React from 'react';

const WelcomeScreen = ({ numQuestions, setNumQuestions, startQuiz, hasSavedQuiz, resumeQuiz, savedQuizState }) => {
  const questionOptions = [5, 10, 15, 20];
  return (
    <div className="vocab-quiz-instructions">
      <h2>Welcome to the Vocabulary Quiz!</h2>
      <p>Please follow these instructions to complete the quiz:</p>
        <ul className="vocab-quiz-instructions-list">
          <li>Read each question carefully to ensure you understand what is being asked.</li>
          <li>Choose the correct answer from the options.</li>
          <li>Click the "Check Answer" button to submit your answer.</li>
          <li>Click the 'Continue' button to proceed to the next question.</li>
          <li>Review the feedback and additional information provided for each question.</li>
        </ul>
      {hasSavedQuiz && savedQuizState && (
        <div className="vocab-saved-quiz-info">
          <h3>You have a saved quiz:</h3>
          <p>Quiz Type: Vocabulary</p>
          <p>Progress: {savedQuizState.currentQuestion + 1} / {savedQuizState.questions.length} questions</p>
          <p>Current Score: {savedQuizState.score}</p>
          <button className="quiz-resume-button quiz-button" onClick={resumeQuiz}>
            Resume Quiz
          </button>
        </div>
      )}
      <select
        value={numQuestions}
        onChange={(e) => setNumQuestions(parseInt(e.target.value))}
      >
        {questionOptions.map((option) => (
          <option key={option} value={option}>
            {option} Questions
          </option>
        ))}
      </select>
      <button className="quiz-start-button quiz-button start-quiz-btn" onClick={startQuiz}>
        Start Quiz
      </button>
    </div>
  );
};

export default WelcomeScreen;