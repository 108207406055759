import React from 'react';

const QuizOptions = ({ currentQuiz, selectedOption, handleOptionClick, showAnswer }) => (
  <ul className="vocab-quiz-options">
    {Object.values(currentQuiz.options).map((value, index) => (
      <li key={index}>
        <label>
          <input
            type="radio"
            name="option"
            value={value}
            checked={selectedOption === value}
            onChange={() => handleOptionClick(value)}
            disabled={showAnswer}
          />
          {value}
        </label>
      </li>
    ))}
  </ul>
);

export default QuizOptions;