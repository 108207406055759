import React, { useState, useEffect } from "react";
import { getAuth, updateProfile } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "./firebase"; // Assuming you have Firestore initialized
import "./ProfilePage.css"; // Create this CSS file for styling

const ProfilePage = () => {

  useEffect(() => {
    document.title = "Profile Page";
    return () => {
      document.title = "Campus Goals"; // Reset title when component unmounts
    };
  }, []);
  
  const [user, setUser] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setDisplayName(currentUser.displayName || "");

        // Check if user document exists, if not, create it
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (!userDoc.exists()) {
          await setDoc(userDocRef, {
            firstName: "",
            lastName: "",
            email: currentUser.email,
          });
        }

        // Fetch user data
        const userData = userDoc.data();
        setFirstName(userData?.firstName || "");
        setLastName(userData?.lastName || "");
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (!user) {
      setError("You must be logged in to update your profile.");
      return;
    }

    try {
      // Update display name in Firebase Auth
      await updateProfile(user, { displayName });

      // Update or create user document in Firestore
      const userDocRef = doc(db, "users", user.uid);
      await setDoc(userDocRef, {
        firstName,
        lastName,
        displayName,
        email: user.email,
      }, { merge: true });

      setSuccess("Profile updated successfully!");
    } catch (error) {
      setError("An error occurred. Please try again later.");
      console.error("Error updating profile:", error);
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile-form-wrapper">
      <div className="profile-form-main">
        <h2 className="profile-form-instruction">Update Your Profile</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="profile-form-label">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={user.email}
              disabled
              className="profile-form-input"
            />
          </div>
          <div>
            <label htmlFor="displayName" className="profile-form-label">
              Display Name
            </label>
            <input
              type="text"
              id="displayName"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              required
              className="profile-form-input"
            />
          </div>
          <div>
            <label htmlFor="firstName" className="profile-form-label">
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              className="profile-form-input"
            />
          </div>
          <div>
            <label htmlFor="lastName" className="profile-form-label">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              className="profile-form-input"
            />
          </div>
          <button type="submit" className="profile-form-button">
            Save Changes
          </button>
        </form>
        {success && <div className="profile-form-success">{success}</div>}
        {error && <div className="profile-form-error">{error}</div>}
      </div>
    </div>
  );
};

export default ProfilePage;