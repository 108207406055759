import React, { useState, useEffect, useCallback } from 'react';
import './rcQuiz.css';
import { collection, query, where, getDocs, doc, setDoc, getDoc, deleteDoc } from 'firebase/firestore';
import { db } from './firebase';

const RcQuiz = () => {
  const [randomizedPassages, setRandomizedPassages] = useState([]);
  const [currentPassageIndex, setCurrentPassageIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [showExplanation, setShowExplanation] = useState(false);
  const [answerStatus, setAnswerStatus] = useState(null);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [quizSummary, setQuizSummary] = useState([]);
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [hasIncompleteQuiz, setHasIncompleteQuiz] = useState(false);
  const [savedQuizState, setSavedQuizState] = useState(null);

  useEffect(() => {
    document.title = "Reading Comprehension Passage Quiz";
    return () => {
      document.title = "Campus Goals";
    };
  }, []);

  const getRandomPassages = useCallback(async () => {
    try {
      const q = query(collection(db, 'users'), where('quiztype', '==', 'readingcomprehension'));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map(doc => doc.data());
      console.log("Fetched passages:", data);
      return data.sort(() => Math.random() - 0.5).slice(0, 2);
    } catch (error) {
      console.error("Error fetching passages:", error);
      return [];
    }
  }, []);

  const shuffleOptions = useCallback((options) => {
    return Object.values(options).sort(() => Math.random() - 0.5);
  }, []);

  const saveQuizState = useCallback(async (state) => {
    try {
      await setDoc(doc(db, 'rcquizStates', 'currentState'), state);
    } catch (error) {
      console.error('Error saving quiz state: ', error);
    }
  }, []);

  const loadQuizState = useCallback(async () => {
    try {
      const quizStateRef = doc(db, 'rcquizStates', 'currentState');
      const quizStateSnap = await getDoc(quizStateRef);

      if (quizStateSnap.exists()) {
        const state = quizStateSnap.data();
        setSavedQuizState(state);
        setHasIncompleteQuiz(true);
      } else {
        setHasIncompleteQuiz(false);
        setSavedQuizState(null);
      }
    } catch (error) {
      console.error('Error loading quiz state: ', error);
    }
  }, []);

  const deleteQuizState = useCallback(async () => {
    try {
      await deleteDoc(doc(db, 'rcquizStates', 'currentState'));
      setHasIncompleteQuiz(false);
      setSavedQuizState(null);
    } catch (error) {
      console.error('Error deleting quiz state: ', error);
    }
  }, []);

  const saveCurrentQuizState = useCallback(() => {
    saveQuizState({
      randomizedPassages,
      currentPassageIndex,
      currentQuestion,
      selectedAnswers,
      showExplanation,
      answerStatus,
      correctAnswers,
      showScore,
      quizSummary,
      shuffledOptions,
      isAnswerSubmitted,
    });
  }, [
    randomizedPassages,
    currentPassageIndex,
    currentQuestion,
    selectedAnswers,
    showExplanation,
    answerStatus,
    correctAnswers,
    showScore,
    quizSummary,
    shuffledOptions,
    isAnswerSubmitted,
    saveQuizState
  ]);

  const startNewQuiz = useCallback(async () => {
    setIsLoading(true);
    try {
      await deleteQuizState();
      const newPassages = await getRandomPassages();
      setRandomizedPassages(newPassages);
      setCurrentPassageIndex(0);
      setCurrentQuestion(0);
      setSelectedAnswers({});
      setShowExplanation(false);
      setAnswerStatus(null);
      setCorrectAnswers(0);
      setShowScore(false);
      setQuizSummary([]);
      setIsAnswerSubmitted(false);
      setShowWelcome(false);
      if (newPassages.length > 0) {
        const firstQuestionData = newPassages[0].questions[0];
        const shuffled = shuffleOptions(firstQuestionData.options);
        setShuffledOptions(shuffled);
      }
    } catch (error) {
      console.error("Error starting new quiz:", error);
    } finally {
      setIsLoading(false);
    }
  }, [getRandomPassages, shuffleOptions, deleteQuizState]);

  const resumeQuiz = useCallback(async () => {
    setIsLoading(true);
    try {
      if (savedQuizState && savedQuizState.randomizedPassages) {
        console.log('Saved quiz state:', savedQuizState);
        setRandomizedPassages(savedQuizState.randomizedPassages);
        setCurrentPassageIndex(savedQuizState.currentPassageIndex);
        setCurrentQuestion(savedQuizState.currentQuestion);
        setSelectedAnswers(savedQuizState.selectedAnswers || {});
        setShowExplanation(savedQuizState.showExplanation || false);
        setAnswerStatus(savedQuizState.answerStatus || null);
        setCorrectAnswers(savedQuizState.correctAnswers || 0);
        setShowScore(savedQuizState.showScore || false);
        setQuizSummary(savedQuizState.quizSummary || []);
        setIsAnswerSubmitted(savedQuizState.isAnswerSubmitted || false);

        const currentPassage = savedQuizState.randomizedPassages[savedQuizState.currentPassageIndex];
        if (currentPassage && currentPassage.questions && currentPassage.questions[savedQuizState.currentQuestion]) {
          const currentQuestionData = currentPassage.questions[savedQuizState.currentQuestion];
          if (currentQuestionData.options) {
            setShuffledOptions(shuffleOptions(currentQuestionData.options));
          } else {
            console.error('No options found for current question when resuming');
            setShuffledOptions([]);
          }
        } else {
          console.error('Unable to access current question data when resuming');
          setShuffledOptions([]);
        }
        setShowWelcome(false);
      } else {
        console.error('No saved quiz state found or invalid state');
        await startNewQuiz();
      }
    } catch (error) {
      console.error("Error resuming quiz:", error);
      await startNewQuiz();
    } finally {
      setIsLoading(false);
    }
  }, [savedQuizState, shuffleOptions, startNewQuiz]);

  useEffect(() => {
    const initializeQuiz = async () => {
      setIsLoading(true);
      try {
        await loadQuizState();
        if (randomizedPassages.length === 0) {
          const newPassages = await getRandomPassages();
          setRandomizedPassages(newPassages);
        }
      } catch (error) {
        console.error("Error initializing quiz:", error);
      } finally {
        setIsLoading(false);
      }
    };
    initializeQuiz();
  }, [loadQuizState, getRandomPassages, randomizedPassages.length]);

  useEffect(() => {
    if (!isAnswerSubmitted) {
      setSelectedAnswers({});
      setShowExplanation(false);
      setAnswerStatus(null);
    }

    if (randomizedPassages.length > 0 && 
        randomizedPassages[currentPassageIndex] && 
        randomizedPassages[currentPassageIndex].questions && 
        randomizedPassages[currentPassageIndex].questions[currentQuestion]) {
      const currentQuestionData = randomizedPassages[currentPassageIndex].questions[currentQuestion];
      console.log('Current question data:', currentQuestionData);
      if (currentQuestionData.options) {
        const shuffled = shuffleOptions(currentQuestionData.options);
        setShuffledOptions(shuffled);
      } else {
        console.error('No options found for current question');
        setShuffledOptions([]);
      }
    } else {
      console.error('Unable to access current question data');
      setShuffledOptions([]);
    }
  }, [currentPassageIndex, currentQuestion, randomizedPassages, shuffleOptions, isAnswerSubmitted]);

  const handleAnswerClick = useCallback((answer) => {
    const currentQuestionData = randomizedPassages[currentPassageIndex]?.questions[currentQuestion];
    if (!currentQuestionData) return;

    if (currentQuestionData.type === 'single') {
      setSelectedAnswers({ [answer]: true });
    } else if (currentQuestionData.type === 'multiple') {
      setSelectedAnswers(prevState => ({ ...prevState, [answer]: !prevState[answer] }));
    }
  }, [randomizedPassages, currentPassageIndex, currentQuestion]);

  const isSubmitEnabled = useCallback(() => {
    const currentQuestionData = randomizedPassages[currentPassageIndex]?.questions[currentQuestion];
    if (!currentQuestionData) return false;

    const selectedCount = Object.values(selectedAnswers).filter(Boolean).length;

    if (currentQuestionData.type === 'single') {
      return selectedCount === 1;
    } else if (currentQuestionData.type === 'multiple') {
      return selectedCount >= 2;
    }

    return false;
  }, [randomizedPassages, currentPassageIndex, currentQuestion, selectedAnswers]);

  const handleSubmit = () => {
    const currentQuestionData = randomizedPassages[currentPassageIndex].questions[currentQuestion];
    let isCorrect = false;
    let userAnswer = [];

    if (currentQuestionData.type === 'single') {
      userAnswer = Object.keys(selectedAnswers)[0];
      isCorrect = userAnswer === currentQuestionData.correct_answer;
    } else if (currentQuestionData.type === 'multiple') {
      userAnswer = Object.keys(selectedAnswers).filter(key => selectedAnswers[key]);
      isCorrect = currentQuestionData.correct_answer.every(ans => userAnswer.includes(ans)) && userAnswer.every(ans => currentQuestionData.correct_answer.includes(ans));
    }

    const newQuizSummary = [
      ...quizSummary,
      {
        passage: randomizedPassages[currentPassageIndex].passage,
        question: currentQuestionData.question,
        userAnswer: userAnswer,
        correctAnswer: currentQuestionData.correct_answer,
        explanation: currentQuestionData.explanation,
        isCorrect: isCorrect
      }
    ];

    setQuizSummary(newQuizSummary);
    setShowExplanation(true);
    setIsAnswerSubmitted(true);
    setAnswerStatus(isCorrect ? 'correct' : 'incorrect');
    const newCorrectAnswers = isCorrect ? correctAnswers + 1 : correctAnswers;
    setCorrectAnswers(newCorrectAnswers);

    // Save the current state after updating
    saveQuizState({
      randomizedPassages,
      currentPassageIndex,
      currentQuestion,
      selectedAnswers,
      showExplanation: true,
      answerStatus: isCorrect ? 'correct' : 'incorrect',
      correctAnswers: newCorrectAnswers,
      showScore,
      quizSummary: newQuizSummary,
      shuffledOptions,
      isAnswerSubmitted: true,
    });
  };

  const handleNextQuestion = () => {
    let nextQuestionIndex = currentQuestion + 1;
    let nextPassageIndex = currentPassageIndex;

    if (nextQuestionIndex >= randomizedPassages[currentPassageIndex].questions.length) {
      nextPassageIndex++;
      nextQuestionIndex = 0;
    }

    if (nextPassageIndex >= randomizedPassages.length) {
      setShowScore(true);
    } else {
      setCurrentPassageIndex(nextPassageIndex);
      setCurrentQuestion(nextQuestionIndex);
      setSelectedAnswers({});
      setShowExplanation(false);
      setAnswerStatus(null);
      setIsAnswerSubmitted(false);
    }

    // Save the updated state
    saveQuizState({
      randomizedPassages,
      currentPassageIndex: nextPassageIndex,
      currentQuestion: nextQuestionIndex,
      selectedAnswers: {},
      showExplanation: false,
      answerStatus: null,
      correctAnswers,
      showScore: nextPassageIndex >= randomizedPassages.length,
      quizSummary,
      shuffledOptions,
      isAnswerSubmitted: false,
    });
  };

  const restartQuiz = async () => {
    await deleteQuizState();
    setShowWelcome(true);
    setShowScore(false);
    setCurrentPassageIndex(0);
    setCurrentQuestion(0);
    setSelectedAnswers({});
    setShowExplanation(false);
    setAnswerStatus(null);
    setCorrectAnswers(0);
    setQuizSummary([]);
    setIsAnswerSubmitted(false);
  };

  const renderOptions = () => {
    const currentQuestionData = randomizedPassages[currentPassageIndex]?.questions[currentQuestion];
    if (!currentQuestionData || !shuffledOptions.length) {
      console.error('No question data or options available');
      return null;
    }

    return shuffledOptions.map((value, index) => {
      const label = String.fromCharCode(65 + index);
      return (
        <label 
          key={label} 
          className={`
            rc-option-label 
            ${selectedAnswers[value] ? 'selected' : ''}
            ${showExplanation && 
              (currentQuestionData.type === 'single' 
                ? currentQuestionData.correct_answer === value
                : currentQuestionData.correct_answer.includes(value)) 
              ? 'correct' : ''}
            ${showExplanation && 
              (currentQuestionData.type === 'single'
                ? currentQuestionData.correct_answer !== value && selectedAnswers[value]
                : !currentQuestionData.correct_answer.includes(value) && selectedAnswers[value]) 
              ? 'incorrect' : ''}
          `}
          style={{width: '100%', justifyContent: 'flex-start'}}
        >
          <input
            type={currentQuestionData.type === 'multiple' ? 'checkbox' : 'radio'}
            name="quiz-option"
            value={value}
            checked={!!selectedAnswers[value]}
            onChange={() => handleAnswerClick(value)}
            disabled={showExplanation}
            style={{marginRight: '10px'}}
          />
          <span className="rc-option-text">{label}. {value}</span>
        </label>
      );
    });
  };

  const QuizSummary = ({ summary }) => (
    <div className="rc-quiz-summary">
      <h3>Quiz Summary</h3>
      {summary.map((item, index) => (
        <div key={index} className={`rc-summary-item ${item.isCorrect ? 'correct' : 'incorrect'}`}>
          <p><strong>Passage:</strong> {item.passage[0].substring(0, 100)}...</p>
          <p><strong>Q{index + 1}:</strong> {item.question}</p>
          <p>Your answer: {Array.isArray(item.userAnswer) ? item.userAnswer.join(', ') : item.userAnswer}</p>
          <p>Correct answer: {Array.isArray(item.correctAnswer) ? item.correctAnswer.join(', ') : item.correctAnswer}</p>
          <p>Explanation: {item.explanation}</p>
        </div>
      ))}
    </div>
  );

  if (isLoading) {
    return <div className="rc-quiz-container">Loading...</div>;
  }

  if (randomizedPassages.length === 0) {
    return <div className="rc-quiz-container">No passages available. Please try again later.</div>;
  }

  return (
    <div className="rc-quiz-container">
      {showWelcome ? (
        <div className="rc-welcome-screen">
          <h2 className="rc-welcome-header">Welcome to Reading Comprehension Quiz</h2>
          <div className="rc-quiz-instructions">
            <p>This quiz is designed to test your ability to understand and analyze complex passages on a variety of contemporary topics.</p>
            <p>For each passage, you'll be presented with a series of questions designed to assess your comprehension and analytical skills. These questions will come in two formats:</p>
            <ol>
              <li><strong>Single Answer Questions:</strong> Choose the best answer from the options provided.</li>
              <li><strong>Multiple Answer Questions:</strong> Select all correct answers from the options given.</li>
            </ol>
            <p>The questions will test various aspects of your understanding, including:</p>
            <ul>
              <li>Identifying the main idea or primary concern of the passage</li>
              <li>Understanding the author's tone and purpose</li>
              <li>Making inferences based on the information provided</li>
              <li>Recognizing specific details and their significance</li>
              <li>Analyzing the structure and logic of the arguments presented</li>
            </ul>
            <p>Remember to read each passage carefully and consider the context when answering the questions. Some questions may require you to synthesize information from different parts of the passage.</p>
            <p>This quiz is designed to challenge your critical thinking and analytical skills. Take your time, and don't hesitate to refer back to the passage as needed. Good luck!</p>
          </div>
          <div className="rc-welcome-button-container">
            {hasIncompleteQuiz && savedQuizState && (
              <div className="rc-saved-quiz-info">
                <p>You have a saved quiz. Would you like to resume?</p>
                <p>
                  Progress: {
                    savedQuizState.randomizedPassages
                      .slice(0, savedQuizState.currentPassageIndex)
                      .reduce((total, passage) => total + passage.questions.length, 0) +
                    savedQuizState.currentQuestion + 1
                  } /{" "}
                  {savedQuizState.randomizedPassages.reduce((total, passage) => total + passage.questions.length, 0)} questions
                </p>
                <p>
                  Current Score:{" "}
                  {savedQuizState.quizSummary.filter((item) => item.isCorrect).length}
                </p>
                <button onClick={resumeQuiz} className="rc-resume-button">
                  Resume Quiz
                </button>
              </div>
            )}
            <button className="rc-welcome-button" onClick={startNewQuiz}>Start A New Quiz</button>
          </div>
        </div>
      ) : showScore ? (
        <>
          <h2>Quiz Completed!</h2>
          <h3>You scored {correctAnswers} out of {randomizedPassages.reduce((total, passage) => total + passage.questions.length, 0)}</h3>
          <QuizSummary summary={quizSummary} />
          <div className="rc-new-quiz-button-container">
            <button className="rc-new-quiz-button" onClick={restartQuiz}>Restart Quiz</button>
          </div>
        </>
      ) : (
        <>
          {randomizedPassages[currentPassageIndex] && (
            <>
              <h2>Reading Comprehension Passage {currentPassageIndex + 1} of {randomizedPassages.length}</h2>
              <div className="rc-passage-text">
                {randomizedPassages[currentPassageIndex].passage.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </div>
            </>
          )}
          {randomizedPassages[currentPassageIndex]?.questions[currentQuestion] && (
            <>
              <h3>Question {currentQuestion + 1} of {randomizedPassages[currentPassageIndex].questions.length}</h3>
              <p className="rc-question-text">{randomizedPassages[currentPassageIndex].questions[currentQuestion].question}</p>
              <p className="rc-question-type">
                {randomizedPassages[currentPassageIndex].questions[currentQuestion].type === 'single' 
                  ? 'Select one answer' 
                  : 'Select at least two answers'}
              </p>
              <div className="rc-options-list">
                {renderOptions()}
              </div>
              {!isAnswerSubmitted && (
                <div className="rc-button-container">
                  <button 
                    onClick={handleSubmit} 
                    disabled={!isSubmitEnabled()}
                  >
                    Submit
                  </button>
                </div>
              )}
              {showExplanation && (
                <div className="rc-explanation">
                  <p className={`rc-answer-status ${answerStatus}`}>
                    {answerStatus === 'correct' ? 'Correct!' : 'Incorrect!'}
                  </p>
                  <p>{randomizedPassages[currentPassageIndex].questions[currentQuestion].explanation}</p>
                </div>
              )}
              {isAnswerSubmitted && (
                <div className="rc-button-container">
                  <button onClick={handleNextQuestion}>
                    {currentQuestion < randomizedPassages[currentPassageIndex].questions.length - 1 || currentPassageIndex < randomizedPassages.length - 1 ? 'Next Question' : 'Finish Quiz'}
                  </button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default RcQuiz;