const QuizQuestion = ({ currentQuiz, currentQuestion, questions, score, handlePlayButtonClick, audioRef }) => {
  if (!currentQuiz) {
    return <div>Loading question...</div>;
  }

  return (
    <>
      <div className="vocab-quiz-header">
        <span className="vocab-quiz-question-number">
          Question {currentQuestion + 1} of {questions.length}
        </span>
      </div>
      <div className="vocab-quiz-word-container">
        <h2 className="vocab-quiz-word">
          {currentQuiz.word}
          <span className="vocab-quiz-part-of-speech">({currentQuiz.part_of_speech})</span>
          <button 
            onClick={handlePlayButtonClick}
            className="vocab-quiz-play-button"
            aria-label="Play pronunciation"
          >
            ▶️
          </button>
        </h2>
      </div>
      <audio ref={audioRef} src={`/audio/${currentQuiz.word}.mp3`} className="vocab-quiz-audio">
        Your browser does not support the audio element.
      </audio>
    </>
  );
};

export default QuizQuestion;