export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const correctFeedback = [
  'Well done!',
  'Nice work!',
  'Great job!',
  'Correct!',
  'You got it!',
];

export const incorrectFeedback = [
  'Oops, that wasn\'t it.',
  'Not quite, but good effort.',
  'Incorrect, but nice try.',
  'Wrong answer, but thanks for playing.',
  'Not the right choice, but you played well.',
];