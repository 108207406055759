import React from 'react';

const Flashcard = ({ word, onAnswer, selectedAnswer, answerStatus, randomizedAnswers, quizType }) => {
  return (
    <div className="syn-flashcard">
      <div className="syn-word-container">
        {quizType === 'synonyms' ? (
          <h2>
            <span className="means">What is a synonym for "</span>
            <span>{word.word}</span>
            <span className="means">"?</span>
          </h2>
        ) : (
          <h2>
            <span className="means">What is an antonym for "</span>
            <span>{word.word}</span>
            <span className="means">"?</span>
          </h2>
        )}
      </div>
      <div className="syn-synonyms">
        <div className="syn-answer-options">
          {randomizedAnswers.map((text, index) => (
            <button 
              key={index} 
              className={`
                syn-option-button
                ${selectedAnswer === text ? 'selected' : ''}
                ${answerStatus && word.correctAnswer === text ? 'correct' : ''}
                ${answerStatus && selectedAnswer === text && word.correctAnswer !== text ? 'incorrect' : ''}
                ${answerStatus !== null ? 'answered' : ''}
              `}
              onClick={() => onAnswer(text)}
              disabled={answerStatus !== null}
            >
              <span className="syn-option-text">{text}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Flashcard;