import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 0;
  overflow: hidden;
  opacity: ${props => props.imagesLoaded ? 1 : 0};
  transition: opacity 0.3s ease-in;

  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledImage = styled.img`
  height: 40%;
  width: auto;
  object-fit: contain;
`;

const TopImages = () => {
  const [leftImageLoaded, setLeftImageLoaded] = useState(false);
  const [rightImageLoaded, setRightImageLoaded] = useState(false);

  const allImagesLoaded = leftImageLoaded && rightImageLoaded;

  useEffect(() => {
    // Preload images
    const leftImage = new Image();
    const rightImage = new Image();

    leftImage.src = "/campusgoals-website-left-image.png";
    rightImage.src = "/campusgoals-website-right-image.png";

    leftImage.onload = () => setLeftImageLoaded(true);
    rightImage.onload = () => setRightImageLoaded(true);
  }, []);

  return (
    <ImageContainer imagesLoaded={allImagesLoaded}>
      <StyledImage 
        src="/campusgoals-website-left-image.png" 
        alt="Student studying" 
        onLoad={() => setLeftImageLoaded(true)}
      />
      <StyledImage 
        src="/campusgoals-website-right-image.png" 
        alt="Student with laptop" 
        onLoad={() => setRightImageLoaded(true)}
      />
    </ImageContainer>
  );
};

export default TopImages;