import React, { useEffect } from 'react';
import "./AboutUs.css"; // Import the CSS file

const AboutUs = () => {

  useEffect(() => {
    document.title = "Abous Us";
    return () => {
      document.title = "Campus Goals"; // Reset title when component unmounts
    };
  }, []);
  
  return (
    <div className="about-us-wrapper">
      <div className="about-us-main">
        <h2 className="about-us-header">About Us</h2>
        <p className="about-us-description">
          Welcome to CampusGoals.com, your premier destination for comprehensive test preparation resources! We specialize in providing high-quality study materials for a variety of standardized tests, including the GRE, ACT, SAT, and more. Our mission is to empower students to achieve their academic goals by offering expertly crafted content that caters to diverse learning styles.
        </p>
        <p className="about-us-description">
          At CampusGoals.com, we understand that preparing for tests can be a daunting task. That's why we've curated a wide range of resources, from practice questions and detailed study guides to interactive quizzes. Our team of experienced educators and test prep specialists is dedicated to helping you navigate the complexities of test preparation with confidence.
        </p>
        <p className="about-us-description">
          Whether you're a high school student gearing up for college entrance exams or a graduate student preparing for advanced studies, we are here to support you every step of the way. Join our community of learners and take the first step towards your success today!
        </p>
      </div>
    </div>
  );
};

export default AboutUs;