import React, { useState, useEffect } from "react";
import { ref, push } from "firebase/database";
import { database } from "./firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth"; // Import Firebase Auth
import "./ContactForm.css"; // Import the CSS file

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [user, setUser] = useState(null); // State to hold user info

  useEffect(() => {
    document.title = "Contact Us";
    return () => {
      document.title = "Campus Goals"; // Reset title when component unmounts
    };
  }, []);

  useEffect(() => {
    const auth = getAuth();
    // Check if user is authenticated
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSubmitted(false);

    if (!user) {
      setError("You must be logged in to submit the form.");
      return;
    }

    try {
      const contactRef = ref(database, "contacts");
      await push(contactRef, {
        name,
        email,
        message,
        timestamp: Date.now(),
        recipientEmail: "campusgoals.team@gmail.com", // Ensure this is the recipient email
      });

      // Clear the form fields
      setName("");
      setEmail("");
      setMessage("");
      setSubmitted(true);
    } catch (error) {
      setError("An error occurred. Please try again later.");
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="contact-form-wrapper">
      <div className="contact-form-main">
        <h2 className="contact-form-instruction">
          We'd love to hear from you!
          <br />
          <br />
          Please fill out the form below and we'll get back to you as soon as
          possible.
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="contact-form-label">
              Name
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="contact-form-input"
            />
          </div>
          <div>
            <label htmlFor="email" className="contact-form-label">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="contact-form-input"
            />
          </div>
          <div>
            <label htmlFor="message" className="contact-form-label">
              Message
            </label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              rows={6} // Increased rows to 6 for height
              className="contact-form-textarea" // Use a specific class for textarea
            ></textarea>
          </div>
          <button type="submit" className="contact-form-button">
            Submit
          </button>
        </form>
        {submitted && (
          <div className="contact-form-success">
            Thank you for your message. We'll get back to you soon!
          </div>
        )}
        {error && <div className="contact-form-error">{error}</div>}
      </div>
    </div>
  );
};

export default ContactForm;
