import React from 'react';

const FeedbackSection = ({ feedback, currentQuiz, selectedOption, handleNextQuestion, isLastQuestion }) => (
  <div>
    <p className={`vocab-quiz-feedback ${feedback.includes('Correct') ? 'correct' : 'incorrect'}`}>
      {feedback}
    </p>
    {selectedOption !== currentQuiz.correct_answer && (
      <div className="vocab-quiz-feedback-container">
        <p className="vocab-quiz-incorrect-answer">
          <strong>The correct answer is:</strong> {currentQuiz.correct_answer}
        </p>
        <p className="vocab-quiz-explanation"><strong>Explanation:</strong> {currentQuiz.explanation}</p>
        <p className="vocab-quiz-sample-sentence"><strong>Sample Sentence:</strong> {currentQuiz.sample_sentence}</p>
        <p className="vocab-quiz-tips-to-remember"><strong>Tips to Remember:</strong> {currentQuiz.tips_to_remember}</p>
        <p className="vocab-quiz-additional-info"><strong>Additional Info:</strong> {currentQuiz.additional_info}</p>
      </div>
    )}
    <button className="vocab-quiz-button" onClick={handleNextQuestion}>
      {isLastQuestion ? 'Check Score' : 'Next Question'}
    </button>
  </div>
);

export default FeedbackSection;