import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 0px;
  box-sizing: border-box;
  background-color: #f9f9f9;
`;

const Content = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background-color: f9f9f9;
  padding: 0px;
  border-radius: 0px;
  
  position: relative;
  z-index: 1;

  @media (max-width: 1024px) {
    max-width: 90%;
  }
`;

const ContentWrapper = ({ children }) => {
  return (
    <Wrapper>
      {children[0]} {/* TopImages */}
      <Content>{children.slice(1)}</Content> {/* Main content */}
    </Wrapper>
  );
};

export default ContentWrapper;