import React from 'react';
import { InlineMath, BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';

const LatexRenderer = ({ text }) => {
  // Function to convert "X.XX USD" to "$X.XX"
  const convertUSDFormat = (str) => {
    return str.replace(/(\d{1,3}(,\d{3})*(\.\d+)?) USD/g, (match, p1) => {
      const [integerPart, decimalPart] = p1.split('.');
      const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (decimalPart) {
        return `$${formattedIntegerPart}.${decimalPart.slice(0, 2).padEnd(2, '0')}`;
      } else {
        return `$${formattedIntegerPart}`;
      }
    });
  };

  // Function to process LaTeX content
  const processLatex = (latex) => {
    // Replace all \frac commands with \displaystyle{\frac{...}{...}}
    return latex.replace(/\\frac\{([^}]*)\}\{([^}]*)\}/g, '\\displaystyle{\\frac{$1}{$2}}');
  };

  // Regex for mathematical equations
  const regex = /\$\$(.*?)\$\$|\$(.*?)\$/g;
  const parts = [];
  let lastIndex = 0;
  let match;

  // KaTeX rendering options
  const katexOptions = {
    trust: true,
    strict: false,
    fontSize: 18 // Increase font size
  };

  while ((match = regex.exec(text)) !== null) {
    if (lastIndex < match.index) {
      parts.push(convertUSDFormat(text.slice(lastIndex, match.index)));
    }
    let latex = match[1] || match[2];
    const isBlock = !!match[1];

    // Process the LaTeX content
    latex = processLatex(latex);

    parts.push(
      isBlock ? (
        <BlockMath math={latex} settings={katexOptions} />
      ) : (
        <InlineMath math={latex} settings={katexOptions} />
      )
    );
    lastIndex = match.index + match[0].length;
  }

  if (lastIndex < text.length) {
    parts.push(convertUSDFormat(text.slice(lastIndex)));
  }

  return <div style={{ fontSize: '18px' }}>{parts}</div>;
};

export default LatexRenderer;