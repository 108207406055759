import React from 'react';

const ResultScreen = ({ score, questions, handleRestartQuiz, quizSummary }) => (
  <div className="vocab-quiz-wrapper">
    <div className="vocab-quiz-main">
      <h1 className="vocab-quiz-result">Quiz Completed!</h1>
      <p className="vocab-quiz-result-score">Your final score is: {score} out of {questions.length}</p>
      <p className="vocab-quiz-result-percentage">
        Percentage: {((score / questions.length) * 100).toFixed(2)}%
      </p>
      <div className="quiz-summary">
        <h3>Quiz Summary</h3>
        {quizSummary.map((item, index) => (
          <div key={index} className={`summary-item ${item.isCorrect ? 'correct' : 'incorrect'}`}>
            <p><strong>Word:</strong> {item.question}</p>
            <p>Your answer: {item.userAnswer}</p>
            <p>Correct answer: {item.correctAnswer}</p>
          </div>
        ))}
      </div>
      <button className="vocab-quiz-restart-button quiz-button restart-quiz-btn" onClick={handleRestartQuiz}>
        Restart Quiz
      </button>
    </div>
  </div>
);

export default ResultScreen;